import React from "react"
import { Link } from "gatsby"

const InTreatmentSubmenu = () => {
  return (
    <>
      <li><Link data-gtm-event-category="Top Carousel" data-gtm-event-action="Click" data-gtm-event-label="Non - High Risk (Low or Intermediate Risk) Treatment" activeClassName="isActive" partiallyActive={true} to="/treatment/non-high-risk-neuroblastoma/">Non–High-Risk <br className="show-for-large"/>(Low- or Intermediate-Risk) Treatment <span className="np-arrow">&rsaquo;</span></Link></li>
      <li><Link data-gtm-event-category="Top Carousel" data-gtm-event-action="Click" data-gtm-event-label="High Risk Treatment" activeClassName="isActive" partiallyActive={true} to="/treatment/high-risk-neuroblastoma/">High-Risk Treatment <span className="np-arrow">&rsaquo;</span></Link></li>
    </>
  )
}

export default InTreatmentSubmenu
