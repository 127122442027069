import React from "react"
import * as SassVars from '../../../components/variables.module.scss';
import PropTypes from "prop-types"

const InlineInfocard = (props) => {
  return (
    <div className={`row row--inner ` + props.className}>
      <div className="columns">
        <div className="card" style={{backgroundColor: SassVars.viridian, borderColor: SassVars.turquoise }}>
          <div className="row align-middle">
            <div className="columns text-center">
              <p className="tc-white no-pad jost-med h5">{props.text}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

InlineInfocard.defaultProps = {
  text: 'YOU FORGOT TO ADD A TEXT PROP',
};

InlineInfocard.propTypes = {
  text: PropTypes.node.isRequired
}

export default InlineInfocard
