import React from "react"
import * as SassVars from '../../../components/variables.module.scss';
import PropTypes from "prop-types"
import questionSilo from "../../../images/1.2.0_questionsolo.jpg";
import { Link } from "gatsby"

const QuestionInfocard = (props) => {
  return (
    <div className={`row row--inner align-middle align-center small-col-reverse ` + props.className}>
      <div className="columns">
        <Link to={props.link} className="card" style={{borderColor: SassVars.barney, minHeight: 0 }}>
          <div className="row align-middle">
            <div className="columns large-2 small-12 text-center">
              <img src={questionSilo} style={{maxWidth:`84px` }} width="100%" height="auto" alt="Find more helpful questions to ask your doctor"/>
            </div>
            <div className="columns">
              <p><strong>{props.heading}</strong></p>
              <p className="no-pad">{props.text}</p>
            </div>
          </div>
        </Link>
      </div>
    </div>
  )
}

QuestionInfocard.defaultProps = {
  text: 'Find questions to ask your child’s doctor.',
  heading: 'YOU FORGOT TO ADD A HEADING PROP',
  link: '/'
};

QuestionInfocard.propTypes = {
  text: PropTypes.node.isRequired,
  heading: PropTypes.node.isRequired,
  link: PropTypes.node.isRequired
}

export default QuestionInfocard
